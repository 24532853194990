import React from "react"
import { Link } from "gatsby"
// import SEO from "../components/seo"
import styled from '@emotion/styled'

const Article = styled(`article`)({
  margin: `0 0 2rem 0`,
  minHeight: `20rem`
})

const Header = styled(`header`)({
  marginBottom: `1rem`,
})

const Form = styled(`form`)({
  [`label`]: {
    display: `block`
  },
  [`input`]: {
    display: `inline-block`,
    background: `transparent`,
    border: `none`,
    borderBottom: `1px solid #FFF`,
    color: `#fff`,
    fontSize: `14px`,
    marginRight: `1rem`,
    lineHeight: `1.5rem`,
    cursor: `pointer`,
    textTransform: `uppercase`,
    [`:focus`]: {
      outline: `none`
    }
  },
  [`button`]: {
    display: `inline-block`,
    background: `transparent`,
    color: `#fff`,
    border: `1px solid #FFF`,
    fontSize: `14px`,
    lineHeight: `1.5rem`,
    cursor: `pointer`,
    textTransform: `uppercase`
  }
})

const SubscribePage = ({ data, location }) => {

  return (
    <React.Fragment>
      {/*<SEO title="All events" />*/}
      <Article>
        <section>
          <Form name="Subscribe" method="POST" data-netlify="true" netlify-honeypot="bot-field" action="/thank-you">
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="Subscribe" />
            <input type="hidden" name="subject" value="New subscription via leefwerk.org" />
            <div>
              <label htmlFor="email">YOUR EMAIL</label>
              <input type="email" name="email" />
              <button type="submit">SUBSCRIBE</button>
            </div>
          </Form>
        </section>
      </Article>
    </React.Fragment>
  )
}

export default SubscribePage
